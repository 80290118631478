<template>
  <div class="company-bg">
    <div class="container">
      <div class="banner">
        <div class="banner-title">
          <span></span>
          <span>安全文化专家名录</span>
        </div>
        <img src="../../assets/company/icon-expert.png"
             class="icon-expet" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">
              安全文化专家名录
            </li>
          </ol>
        </nav>

        <div class="filter-box">

          <!-- <select class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 2)">
            <option value=""
                    selected>按行业</option>
            <option :value="item"
                    v-for="(item, index) in industry"
                    :key="index">{{item}}</option>

          </select> -->
          <select class="form-select"
                  aria-label="Default select example"
                  @change="toChangeSelect($event, 3)">
            <option value=""
                    selected>按地区</option>
            <option :value="item.area_id"
                    v-for="(item, index) in citys"
                    :key="index">{{item.area_name}}</option>
          </select>

          <SearchBar @toSearch="toSearch"></SearchBar>
          <!-- <div class="search-box">
            <div class="search">
              <i class="iconfont icon-icon-video-search"></i>
              <input type="text" placeholder="请输入关键字搜索" placeholder-class="input-font" @keyup.enter="toSearch" />
              <i class="iconfont icon-clear" @click="clearSearch"></i>
            </div>
          </div> -->
        </div>

        <div class="expert-list">
          <div class="expert-card"
               v-for="(item) in data.list"
               :key="item.expert_id">
            <div class="expert-intro">
              <span class="expert-name">{{item.real_name}}</span>
              <span class="expert-job">{{item.gender=='1'?'男':'女'}} {{item.tag}}</span>
            </div>
            <div class="expert-shape"></div>
          </div>
        </div>
        <Pagination @change="pageChanged"
                    :current="1"
                    :pageSize="10"
                    :hideOnSinglePage="true"
                    :total="parseInt(data.total)"
                    :showQuickJumper="false"
                    :showTotal="false"
                    placement="center" />
        <!-- <PageNav @pageChanged="pageChanged"
                 :max="calcMaxPage(data.total)"
                 ref="page" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import PageNav from '@/components/PageNav.vue'
import SearchBar from '@/components/SearchBar.vue'
import Pagination from '@/components/Pagination.vue'
import {
  getBaseData,
  expertList
} from '@/network/API.js'
import {
  calcMaxPage
} from '@/utils/tools.js'
export default {
  components: {
    PageNav,
    SearchBar,
    Pagination
  },
  data() {
    return {
      data: {},
      pageNumber: 1,
      searchKey: "",

      industry: [],
      citys: [],
      selIndustry: "",
      selCity: "",
    }
  },
  created() {
    this.getSelectData()
    this.getDataList()
  },
  methods: {
    toSearch(val) {
      this.pageNumber = 1
      this.searchKey = val
      this.getDataList()
    },
    async getDataList(code = "", page = this.pageNumber) {
      this.pageNumber = page
      if (this.$refs.page) {
        this.$refs.page.pageNumber = page
      }

      let data = await expertList({
        page: page,
        page_size: 10,
        industry: this.selIndustry || "",
        city_code: this.selCity || "",
        keyword: this.searchKey,
      })
      this.data = data
    },
    async getSelectData() {
      this.industry = await getBaseData("industry");
      this.citys = await getBaseData("city_list");
    },
    toChangeSelect(e, type) {
      if (type == 2) {
        this.selIndustry = e.target.value
      }
      if (type == 3) {
        this.selCity = e.target.value
      }
      this.getDataList()
    },
    toDetail(item) {
      this.$router.push({
        name: "ExpertDetail",
        params: {
          id: item.expert_id
        }
      });
    },
    pageChanged(val) {
      this.pageNumber = val.page
      this.getDataList("", val.page)
    },
  }
}
</script>
<style scoped>
.search-box {
  right: 0;
  top: 0;
}

.search,
.search input {
  background: #ebf3fd;
}

</style>